import AOS from "aos";
import {
  TweenMax,
  TimelineLite,
  Sine,
  Linear,
  Power0,
  Power1,
  Power2,
  Power3,
  Cubic,
  CSSPlugin,
} from "gsap/all";

export default class AnimationsUtils {
  constructor() {
    this.initDOMElements();
    this.initTimelines();
    this.initEvents();
    this.initAOS();
  }

  initAOS() {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }

  initDOMElements() {
    var $mains = document.body.querySelectorAll("main");
    this.$page = $mains.length > 1 ? $mains[1] : $mains[0];
    this.$animatedEls = this.$page.querySelectorAll("[data-aos]");
  }

  initTimelines() {
    this.timelines = [];
    if (this.$animatedEls) {
      for (var i = 0, j = this.$animatedEls.length; i < j; i++) {
        var data = {
          el: this.$animatedEls[i],
          tween: this.getTimeline(
            this.$animatedEls[i].getAttribute("data-aos"),
            this.$animatedEls[i]
          ),
        };

        this.timelines.push(data);
      }
      // console.log(this.timelines);
    }
  }

  initEvents() {
    document.addEventListener("aos:in", ({ detail }) => {
      this.matchTimeline(detail);
    });
  }

  matchTimeline(el) {
    if (this.$animatedEls) {
      for (var i = 0, j = this.$animatedEls.length; i < j; i++) {
        if (this.timelines[i].el == el) {
          this.timelines[i].tween.play();
        }
      }
    }
  }

  // Refresh for AJAX navigation
  refresh() {
    this.initDOMElements();
    this.initTimelines();
    AOS.refreshHard();
  }
  aosRefresh() {
    AOS.refreshHard();
  }

  /**
   * Get Timeline for SCROLL animation
   * @param {String} `animationName` animation timeline to set
   * @param {Element} `el` element to animate
   */
  getTimeline(animationName, el) {
    var tween = new TimelineLite({ paused: true });

    switch (animationName) {
      /////////////////////////////////////////////////
      // GENERICS
      ////////////////////////////////////////////////

      // GENERIC - TEXT REVEAL
      case "text-reveal":
        var animatedText = el.querySelectorAll(".animated-text");
        var tween = new TimelineLite({
          paused: true,
          onComplete: () => {
            TweenMax.set(animatedText, { clearProps: "all" });
          },
        });
        tween.staggerFromTo(
          animatedText,
          1.1,
          { y: 35, autoAlpha: 0 },
          { y: 0, autoAlpha: 1, ease: Power2.easeOut },
          0.2,
          "start"
        );
        break;

      /////////////////////////////////////////////////
      // HOME
      ////////////////////////////////////////////////
      case "home-header":
        var animatedText = el.querySelectorAll(".animated-text");
        var animatedMedia = el.querySelectorAll(".animated-media");
        tween
          .fromTo(
            animatedMedia,
            2,
            { y: 55, autoAlpha: 0 },
            { y: 0, autoAlpha: 1, ease: Power2.easeOut },
            "start+=0.4"
          )
          .staggerFromTo(
            animatedText,
            1.3,
            { y: 55, autoAlpha: 0 },
            { y: 0, autoAlpha: 1, ease: Power2.easeOut },
            0.2,
            "start"
          );
        break;

      /////////////////////////////////////////////////
      // LIST ARTISTS
      ////////////////////////////////////////////////
      // case "list-artists-header":
      //   var animatedInput = el.querySelectorAll(
      //     ".template-list-artists__header__search__input"
      //   );
      //   var animatedStroke = el.querySelectorAll(
      //     ".template-list-artists__header__search__stroke"
      //   );
      //   var items = Array.prototype.slice.call(
      //     el.parentNode.querySelectorAll(".js-list-artists-item")
      //   );
      //   var animatedItems = items.slice(0, 15);
      //   tween
      //     .fromTo(
      //       animatedInput,
      //       1,
      //       { y: 15, autoAlpha: 0 },
      //       { y: 0, autoAlpha: 1, ease: Power2.easeOut },
      //       "start+=0.15"
      //     )
      //     .fromTo(
      //       animatedStroke,
      //       1.8,
      //       { width: 0 },
      //       { width: "100%", ease: Power2.easeOut },
      //       "start"
      //     )
      //     .staggerFromTo(
      //       animatedItems,
      //       1,
      //       { y: 50, autoAlpha: 0 },
      //       { y: 0, autoAlpha: 1, ease: Power2.easeOut },
      //       0.15,
      //       "start+=0.7"
      //     );
      //   break;

      /////////////////////////////////////////////////
      // SINGLE ARTIST
      ////////////////////////////////////////////////
      case "artist-header":
        var animatedText = el.querySelectorAll(".animated-text");
        var animatedMedia = el.querySelectorAll(".animated-media");
        tween
          .fromTo(
            animatedMedia,
            1.8,
            { y: 55, autoAlpha: 0 },
            { y: 0, autoAlpha: 1, ease: Power2.easeOut },
            "start+=0.35"
          )
          .staggerFromTo(
            animatedText,
            1.5,
            { y: 15, autoAlpha: 0 },
            { y: 0, autoAlpha: 1, ease: Power2.easeOut },
            0.15,
            "start"
          );
        break;

      /////////////////////////////////////////////////
      // LIST SCULPTURE
      ////////////////////////////////////////////////
      case "grid-item-reveal":
      case "list-artists-header":
        var animatedText =
          el.querySelector(".template-list-sculpture__list__item__label") ||
          el.querySelector(".template-list-photography__list__item__label");
        var animatedMedia =
          el.querySelector(
            ".template-list-sculpture__list__item__image-wrapper"
          ) ||
          el.querySelector(
            ".template-list-photography__list__item__image-wrapper"
          );
        tween
          .staggerFromTo(
            [animatedMedia, animatedText],
            2,
            { y: 95 },
            { y: 0, ease: Power2.easeOut },
            0.3,
            "start"
          )
          .staggerFromTo(
            [animatedMedia, animatedText],
            0.65,
            { autoAlpha: 0 },
            { autoAlpha: 1, ease: Power2.easeOut },
            0.3,
            "start+=0.15"
          );
        break;
    }

    return tween;
  }
}
