import AbstractPageController from "./AbstractPageController";
import Logger from "../utils/Logger";
import {
  TweenMax,
  TimelineLite,
  Power2,
  Linear,
  Elastic,
  CSSPlugin,
} from "gsap/all";
import { addEventListener, removeEventListener } from "../utils/EventUtils";

export default class ListPhotographyController extends AbstractPageController {
  constructor(...args) {
    super(...args);
    Logger.log("ListPhotography->constructor()");

    this.initDomElements();
    this.addEventListeners();
    this.initRevealTl();

    this.ready();
  }

  initDomElements() {
    var $mains = document.body.querySelectorAll("main");
    this.$page = $mains.length > 1 ? $mains[1] : $mains[0];
    this.$dropdowns = this.$page.querySelectorAll(".js-dropdown-mobile");
  }

  addEventListeners() {
    if (this.$dropdowns) {
      this.dropdowns = [];
      this.dropdownHandlerListeners = [];
      for (var i = 0, j = this.$dropdowns.length; i < j; i++) {
        var dropdown = {
          listener: this.dropdownClickHandler.bind(this, i),
          cta: this.$dropdowns[i].parentNode.querySelector(
            ".js-mobile-dropdown-cta"
          ),
        };
        this.dropdowns[i] = dropdown;
        this.dropdowns[i].cta.addEventListener(
          "click",
          this.dropdowns[i].listener
        );
      }
      this.toggleGlobalReadMore();
      this.onResizeListener = this.toggleGlobalReadMore.bind(this);
      this.app.on("resize", this.onResizeListener);
    }
  }

  toggleGlobalReadMore() {
    for (var i = 0, j = this.dropdowns.length; i < j; i++) {
      // Store initial state
      var wasOpened = this.$dropdowns[i].classList.contains("open")
        ? true
        : false;

      this.$dropdowns[i].classList.remove("open");
      var maxHeight = this.$dropdowns[i].getBoundingClientRect().height;
      // Open to get real height
      this.$dropdowns[i].classList.add("open");
      var height = this.$dropdowns[i].getBoundingClientRect().height;
      if (maxHeight < height) {
        this.$dropdowns[i].classList.remove("disabled");
        this.dropdowns[i].cta.classList.remove("hide");
      } else {
        this.$dropdowns[i].classList.add("disabled");
        this.dropdowns[i].cta.classList.add("hide");
      }

      if (wasOpened == true) {
        this.$dropdowns[i].classList.add("open");
        this.dropdowns[i].cta.classList.add("active");
      } else {
        this.$dropdowns[i].classList.remove("open");
        this.dropdowns[i].cta.classList.remove("active");
      }
    }
  }

  dropdownClickHandler(index) {
    // Check if it's open
    if (this.$dropdowns[index].classList.contains("open")) {
      this.$dropdowns[index].classList.remove("open");
      this.dropdowns[index].cta.classList.remove("active");
      // var offset = this.$dropdowns[index].getBoundingClientRect().
      var offset =
        window.scrollY +
        this.$dropdowns[index].getBoundingClientRect().top -
        140;
      window.scrollTo(0, offset);
    } else {
      this.$dropdowns[index].classList.add("open");
      this.dropdowns[index].cta.classList.add("active");
    }
  }

  initRevealTl() {
    this.revealTl = new TimelineLite({ paused: true });
  }

  afterReveal(done = null) {
    // this.revealTl.play(0);

    super.afterReveal(done);
  }

  update() {}

  destroy() {
    Logger.log("ListPhotography->destroy()");
  }
}
